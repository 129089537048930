
// with a param of test, this will get the full "test=bacon"
function getParamFromUrl(param) {
    var url=window.location.href;
    var paramPos = url.indexOf('#' + param + '=');
    if (paramPos === -1) {
        paramPos = url.indexOf('&' + param + '=');
    }
    if (paramPos === -1) {
        paramPos = url.indexOf('?' + param + '=');
    }
    var postParamSearch = url.indexOf('#', paramPos+1);
    if (postParamSearch === -1) {
        postParamSearch = url.indexOf('&', paramPos+1);
    }
    if (paramPos === -1) {
        return false;
    }
    else if (postParamSearch !== -1) {
        return url.substring(paramPos, postParamSearch);
    }
    else {
        return url.substring(paramPos);
    }
}

// with a param of test and in the url is "test=bacon", this will return "bacon"
export function getParamValueFromUrl(param) {
    var currentParam = getParamFromUrl(param);
    if (currentParam) {
        var beforeEqual = currentParam.indexOf('=');
        var value = currentParam.substring(beforeEqual+1);
        return decodeURIComponent(value);
    }
    else {
        return false;
    }
}

// with a param of test, this will get the full "test=bacon"
// only supports getting hash params vs getParamFromUrl() which gets GET params too
function getHashParamFromUrl(param) {
    var url=window.location.href;
    var paramPos = url.indexOf('#' + param + '=');
    var postParamSearch = url.indexOf('#', paramPos+1);
    if (postParamSearch === -1) {
        postParamSearch = url.indexOf('&', paramPos+1);
    }
    if (paramPos === -1) {
        return false;
    }
    else if (postParamSearch !== -1) {
        return url.substring(paramPos, postParamSearch);
    }
    else {
        return url.substring(paramPos);
    }
}

// with a param of test and in the url is "test=bacon", this will return "bacon"
// only supports getting hash param values vs getParamValueFromUrl() which gets GET param values too
export function getHashParamValueFromUrl(param) {
    var currentParam = getHashParamFromUrl(param);
    if (currentParam) {
        var beforeEqual = currentParam.indexOf('=');
        var value = currentParam.substring(beforeEqual+1);
        return decodeURIComponent(value);
    }
    else {
        return false;
    }
}

// this will add or update the param in the url with the value provided
// only works with hash params because GET params cannot be modified without a page refresh
export function addHashParamToUrl(param, value, replace=false, encodeValue=true) {
    var url=window.location.href;
    var currentParam = getHashParamFromUrl(param);
    var newUrl = currentParam ? url.replace(currentParam,"") : url;
    var newParam = '';
    if (encodeValue) {
        newParam= '#' + param +'=' + encodeURIComponent(value);
    }
    else {
        newParam= '#' + param +'=' + value;
    }
    newUrl+= newParam;
    if (replace) {
        window.location.replace(newUrl);
    }
    else {
        window.location.href = newUrl;
    }
}

// this will remove the param from the url
// only works with hash params because GET params cannot be modified without a page refresh
export function removeHashParamFromUrl(param, replace=false) {
    var url=window.location.href;
    var currentParam = getHashParamFromUrl(param);
    var newUrl = currentParam ? url.replace(currentParam,"") : url;
    if (replace) {
        window.location.replace(newUrl);
    }
    else {
        window.location.href = newUrl;
    }
}